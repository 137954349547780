<template>
  <div class="main-content">
    <b-card>
      <good-data-table
        :dateFilter="true"
        :button-label="$t('Add Expense')"
        @add-new-info="Add_Expense"
        ref="exp_categories"
        :columns="columns"
        :no-serial="true"
        :total-column="'response.responseData.total'"
        :data-column="'response.responseData.data'"
        :api-endpoint="'/account/expenses'"
      />
    </b-card>
    <b-modal hide-footer no-close-on-backdrop no-close-on-esc v-model="addEditFormModal" :title="isEmpty(expense.id) ? 'Add Expense' : 'Edit Expense'">
      <validation-observer ref="expense_form">
        <b-form @submit.prevent="Submit_Expense">
          <b-row>
            <!-- date  -->
            <b-col sm="12">
              <validation-provider name="date" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group :label="$t('Date') + ' ' + '*'">
                  <b-form-input :state="getValidationState(validationContext)" aria-describedby="date-feedback" type="date" v-model="expense.date"></b-form-input>
                  <b-form-invalid-feedback id="OrderTax-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Account -->
            <b-col sm="12">
              <validation-provider name="Account">
                <b-form-group slot-scope="{ valid, errors }" :label="$t('Account')">
                  <v-select
                    :class="{ 'is-invalid': !!errors.length }"
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="expense.account_id"
                    :reduce="label => label.value"
                    :placeholder="$t('Choose Account')"
                    :options="accounts.map(accounts => ({ label: accounts.account_name, value: accounts.id }))"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Expense_Category  -->
            <b-col sm="12">
              <validation-provider name="category" :rules="{ required: true }">
                <b-form-group slot-scope="{ valid, errors }" :label="$t('Expense Category') + ' ' + '*'">
                  <v-select
                    :class="{ 'is-invalid': !!errors.length }"
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="expense.category_id"
                    :reduce="label => label.value"
                    :placeholder="$t('Choose Category')"
                    :options="expenses_categories.map(ec => ({ label: ec.name, value: ec.id }))"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Amount  -->
            <b-col sm="12">
              <validation-provider name="Amount" :rules="{ required: true, regex: /^\d*\.?\d*$/ }" v-slot="validationContext">
                <b-form-group :label="$t('Amount') + ' ' + '*'">
                  <b-form-input
                    :state="getValidationState(validationContext)"
                    aria-describedby="Amount-feedback"
                    label="Amount"
                    type="number"
                    :placeholder="$t('Amount')"
                    v-model="expense.amount"
                  ></b-form-input>
                  <b-form-invalid-feedback id="Amount-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Details -->
            <b-col sm="12">
              <validation-provider name="Details" :rules="{ required: true }">
                <b-form-group slot-scope="{ valid, errors }" :label="$t('Details') + ' ' + '*'">
                  <textarea
                    :class="{ 'is-invalid': !!errors.length }"
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="expense.details"
                    rows="4"
                    class="form-control"
                    :placeholder="$t('Add more details')"
                  ></textarea>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12">
              <b-form-group>
                <b-button variant="primary" type="submit" :disabled="SubmitProcessing"><i class="i-Yes me-2 font-weight-bold"></i> {{ $t('submit') }}</b-button>
                <div v-once class="typo__p" v-if="SubmitProcessing">
                  <div class="spinner sm spinner-primary mt-3"></div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import jsPDF from "jspdf";
// import "jspdf-autotable";
import { showDangerNotification, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/account`
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { isEmpty } from '@/utilities'

export default {
  metaInfo: {
    title: 'Expense',
  },
  components: {
    GoodDataTable,
  },
  data() {
    return {
      addEditFormModal: false,
      SubmitProcessing: false,
      expense: {},
    }
  },

  computed: {
    ...mapGetters('account', ['accounts', 'expenses_categories']),
    columns() {
      return [
        {
          label: this.$t('Date'),
          field: 'date',
        },
        {
          label: this.$t('Reference'),
          field: 'Ref',
        },

        {
          label: this.$t('Account'),
          field: 'account_name',
        },

        {
          label: this.$t('Amount'),
          field: 'amount',
        },
        {
          label: this.$t('Category'),
          field: 'category_name',
        },
        {
          label: this.$t('Details'),
          field: 'details',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.Edit_Expense(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.Remove_Expense(props)
                  }
                })
              },
            },
          ],
        },
      ]
    },
  },

  methods: {
    isEmpty,
    ...mapActions('account', ['getAccountBasic']),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    Remove_Expense(props) {
      axios
        .delete(apiUrl + '/expenses/' + props.id)
        .then(() => {
          this.$refs.exp_categories.loadItems()
          showSuccessNotification(this, 'Deleted successfully')
        })
        .catch(() => {
          showDangerNotification(this, 'Something went wrong while deleting')
        })
    },
    Edit_Expense(props) {
      this.expense = {
        id: props.id,
        category_id: props.category_id,
        account_id: props.account_id,
        amount: props.amount,
        date: props.date,
        details: props.details,
      }
      this.addEditFormModal = true
    },
    Add_Expense() {
      this.expense = {
        category_id: null,
        account_id: null,
        amount: null,
        date: null,
        details: null,
      }
      this.addEditFormModal = true
    },
    Submit_Expense() {
      this.$refs.expense_form.validate().then(success => {
        if (!success) {
          showDangerNotification(this, 'Please fill all data correctly')
        } else {
          this.SubmitProcessing = true
          if (!isEmpty(this.expense.id)) {
            const id = this.expense.id
            axios
              .put(`${apiUrl}/expenses/${id}`, {
                expense: this.expense,
              })
              .then(response => {
                this.$refs.exp_categories.loadItems()
                // Complete the animation of theprogress bar.
                showSuccessNotification(this, 'Expense updated successfully')
                this.SubmitProcessing = false
                this.addEditFormModal = false
              })
              .catch(error => {
                // Complete the animation of theprogress bar.
                showDangerNotification(this, 'Something went wrong')
                this.SubmitProcessing = false
                this.addEditFormModal = false
              })
          } else {
            axios
              .post(`${apiUrl}/expenses`, {
                expense: this.expense,
              })
              .then(response => {
                this.$refs.exp_categories.loadItems()
                // Complete the animation of theprogress bar.
                showSuccessNotification(this, 'Expense created successfully')
                this.SubmitProcessing = false
                this.addEditFormModal = false
              })
              .catch(error => {
                // Complete the animation of theprogress bar.
                showDangerNotification(this, 'Something went wrong')
                this.SubmitProcessing = false
                this.addEditFormModal = false
              })
          }
        }
      })
    },
  },

  mounted: function () {
    this.getAccountBasic({
      only: ['accounts', 'expenses_categories'],
    })
    this.$refs.exp_categories.loadItems()
  },
}
</script>